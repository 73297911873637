<template>
  <div class="home page">
    <h2 class="accent--text">
      <v-icon color="primary" class="ml-3" large>mdi-monitor-dashboard</v-icon>
      ادارة الموديلات
      <back-button />
    </h2>
    <hr class="my-5 header-divider" />
    <div class="filter-box py-5 px-4 mb-6">
      <h3 class="primary--text mb-3">
        <v-icon class="ml-1">mdi-filter</v-icon>تصنيف حسب
      </h3>
      <v-row align="center">
        <!-- QUERY -->

        <!-- Q -->
        <v-col cols="12" md="6">
          <v-select
            rounded
            append-icon="mdi-map"
            outlined
            :items="
              getSub_category.map(({ id, name }) => ({
                text: name,
                value: id,
              }))
            "
            v-model="getFilter.sub_category_id"
            @input="changeSelected"
            label="التصنيف"
          >
          </v-select>
          <v-text-field
            rounded
            outlined
            solo
            hide-details
            clearable
            label="ابحث بالاسم   "
            v-model="filter.search"
          ></v-text-field>
          <v-btn
            class="my-3"
            @click="handleFilters('search')"
            large
            color="primary"
            >بحث</v-btn
          >
        </v-col>
        <v-col md="2"></v-col>
        <v-col cols="12" md="4">
          <v-btn
            class="my-2"
            @click="
              filter.active = true;
              handleFilters('active')
            "
            block
            large
            color="primary"
          >
            الموديلات المفعلة
          </v-btn>
          <v-btn
            block
            class="my-2"
            @click="
              filter.active = false;
              handleFilters('active')
            "
            large
            color="primary"
          >
            الموديلات الغير مفعلة
          </v-btn>
          <v-btn
            class="my-2"
            block
            @click="
              handleGetAll()
            "
            large
            color="primary"
            >عرض الكل
          </v-btn>
        </v-col>
      </v-row>
    </div>
    <v-chip right class="mb-2" label color="secondary">
      <v-icon class="mx-2"> mdi-map </v-icon> عدد الموديلات
      {{ getTotal_Models }}</v-chip
    >
    <!-- TABLE -->
    <v-data-table
      :headers="headers"
      :items="getModelsList"
      loading-text="جاري تحميل البيانات"
      no-data-text="لا يوجد بيانات"
      hide-default-footer
      disable-filtering
      disable-pagination
      disable-sort
      class="elevation-1"
    >
      <template v-slot:item.checkbox="{ item }">
        <v-checkbox v-model="item.selected" @change="handleCheckboxChange(item)"></v-checkbox>
      </template>

      <template v-slot:top>
        <v-toolbar flat class="d-flex justify-end">
          <v-dialog v-model="dialog" max-width="500px">
            <template v-slot:activator="{ on, attrs }">
              <v-btn color="primary" dark class="my-2" v-bind="attrs" v-on="on">
                إضافة موديل جديدة
              </v-btn>
            </template>
            <v-card>
              <v-card-title class="primary white--text">
                <span class="text-h5">{{ formTitle }}</span>
              </v-card-title>

              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-autocomplete
                        v-model="sub_category_id"
                        rounded
                        append-icon="mdi-map"
                        outlined
                        :items="
                          getSub_category.map(({ id, name }) => ({
                            text: name,
                            value: id,
                          }))
                        "
                        label="التصنيف"
                      >
                      </v-autocomplete>
                      <v-text-field
                        v-model="name_ar"
                        rounded
                        outlined
                        label="الاسم بالعربية"
                      ></v-text-field>
                      <v-text-field
                        v-model="name_en"
                        rounded
                        outlined
                        label="الاسم بالانجليزية"
                      ></v-text-field>
                      <v-text-field
                        v-model="order"
                        outlined
                        rounded
                        type="number"
                        label="الترتيب"
                      ></v-text-field>
                      الحالة :
                      <v-switch class="mx-7" inset v-model="active"></v-switch>
                    </v-col>
                  </v-row>
                </v-container>
              </v-card-text>
              <hr class="header-divider primary" />
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="blue darken-1"
                  dark
                  class="my-2 px-3 py-3"
                  @click="save()"
                >
                  حفظ
                </v-btn>

                <v-btn
                  color="red darken-1"
                  dark
                  class="my-2 mx-2 px-3 py-3"
                  @click="close()"
                >
                  الغاء
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-btn color="error" class="mr-1" @click="deleteSelected">حذف المحدد</v-btn>
        </v-toolbar>
      </template>
      <template v-slot:item.blocked="{ item }">
        <v-switch
            v-if="item.active == 0"
            inset
            :input-value="false"
            @change="unblock(item)"
        ></v-switch>
        <v-switch
            v-else="item.active == 1"
            color="success"
            inset
            :input-value="true"
            @change="block(item)"
        ></v-switch>
      </template>
      <template v-slot:[`item.sub_category`]="{ item }">
        {{ item.sub_category_name }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      </template>
      <template v-slot:[`item.delete`]="{ item }">
        <v-dialog transition="dialog-top-transition" max-width="600">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="error" v-bind="attrs" v-on="on"> حذف </v-btn>
          </template>
          <template v-slot:default="dialog">
            <v-card>
              <v-toolbar color="red" dark>حذف موديل</v-toolbar>
              <v-card-text>
                <h4 class="my-3 red--text">هل تريد حذف هذا الموديل</h4>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn
                  color="red"
                  dark
                  @click="deleteThisRegion(item.id), (dialog.value = false)"
                >
                  حذف
                </v-btn>
                <v-btn text @click="dialog.value = false">الغاء</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </template>
    </v-data-table>

    <!-- PAGINATION -->
    <div class="text-center">
      <v-container>
        <v-row justify="center">
          <v-col cols="8">
            <v-container class="max-width">
              <v-pagination
                :value="+getFilter.page"
                circle
                total-visible="7"
                class="my-4"
                :length="Number(getLastPage)"
                @input="changePage"
              ></v-pagination>
            </v-container>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  name: "Models",

  data: function () {
    return {
      test: "",
      filter: {
        search: "",
        sub_category_id: "",
        active: null,
      },
      sub_category_id: "",
      name_en: "",
      name_ar: "",
      order: "",
      active: false,
      // table
      isLoading: false,
      dialog: false,
      dialogDelete: false,
      headers: [
        {
          text: "حذف",
          value: "checkbox",
        },
        {
          value: "name_ar",
          text: "الاسم بالعربية",
        },
        {
          value: "name_en",
          text: "الاسم بالانجليزية",
        },
        {
          value: "sub_category",
          text: "التصنيف",
        },
        {
          value: "blocked",
          text: "تفعيل/تعطيل",
        },
        {
          value: "actions",
          text: "تعديل",
        },
        {
          text: "حذف",
          value: "delete",
        },
      ],
      editedIndex: -1,
    };
  },

  computed: {
    ...mapGetters("Models", [
      "getModelsList",
      "getTotal_Models",
      "getSub_category",
      "getFilter",
      "getLastPage",
    ]),
    formTitle() {
      return this.editedIndex === -1 ? "إضافة الموديل" : "تعديل الموديل";
    },
  },
  methods: {
    ...mapMutations("Models", ["setFilter"]),
    ...mapActions("Models", [
      "fetchModels",
      "postModel",
      "updateModel",
      "fetchSub_category",
      "deleteModel","multiDeleteRecords"
    ]),
     handleFilters(val){
       this.setFilter({...this.$route.query , [val] : this.filter[val] , page : 1}); 
       this.$router.replace({query:{...this.$route.query , page:1} });
       if(this.$route.query.page == 1){
                this.fetchModels();
        }
     },
     handleGetAll(){
      this.setFilter({...this.$router.query, page:1})
      this.filter={active:null , search : '' , sub_category_id :''}
      this.$router.replace({query:{}})
     },
    changePage(val) {
      this.$router
        .replace({
          query: { ...this.$route.query, page: val },
        })
        .catch(() => {});
    },
    changeSelected(val) {
      this.setFilter({...this.$router.query, page:1}) // to remoce data in search if we change category
      this.$router.push({query:
         {
            ...this.$route.query,
            page: 1,
            sub_category_id: val,
          },
        })
        .catch(() => {});

    },
    editItem(item) {
      this.editedIndex = 500;
      this.id = item.id;
      this.name_en = item.name_en;
      this.name_ar = item.name_ar;
      this.sub_category_id = item.sub_category_id;
      this.order = item.order;
      this.active = item.active;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
      this.name_en = "";
      this.name_ar = "";
      this.sub_category_id = "";
      this.order = "";
      this.active = 0;
      this.$nextTick(() => {
        this.editedIndex = -1;
      });
    },
    save() {
      if (this.editedIndex > -1) {
        this.editRegion();
      } else {
        this.addRegion();
      }
      this.close();
    },
    addRegion() {
      this.postModel({
        name_en: this.name_en,
        name_ar: this.name_ar,
        sub_category_id: this.sub_category_id,
        order: this.order,
        active: this.active,
      }).then(() => {
        this.fetchModels(this.filter);
      });
    },
    editRegion() {
      this.updateModel({
        id: this.id,
        name_en: this.name_en,
        name_ar: this.name_ar,
        sub_category_id: this.sub_category_id,
        order: this.order,
        active: this.active,
      }).then(() => {
        this.fetchModels(this.filter);
      });
    },

    async block(selectedRecord) {
      this.isLoading = true;

      await this.$store
          .dispatch("Models/block", { record: { ...selectedRecord } })
          .then(() => {
            this.$bus.$emit("showSnackbar", {
              text: ` تم حظر   ${selectedRecord.name_ar}`,
              color: "warning",
            });
            this.fetchModels(this.filter);
          })
          .catch((error) => {
            console.log(error)
            this.$bus.$emit("showSnackbar", {
              text: " Error while blocking ",
              color: "red darken-3",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    async unblock(selectedRecord) {
      this.isLoading = true;

      await this.$store
          .dispatch("Models/unblock", { record: { ...selectedRecord } })
          .then(() => {
            this.$bus.$emit("showSnackbar", {
              text: `   تم فك حظر  ${selectedRecord.name_ar}`,
              color: "success",
            });
            this.fetchModels(this.filter);
          })
          .catch((error) => {
            console.log(error)
            this.$bus.$emit("showSnackbar", {
              text: "Error while unblocking   ",
              color: "red darken-3",
            });
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
    deleteThisRegion(id) {
      this.deleteModel(id).then(() => {
        this.fetchModels(this.filter);
      });
    },
    deleteSelected() {

      const selectedRecords = this.getModelsList.filter((item) => item.selected);
      const Ids = selectedRecords.map((item) => item.id);

      if (Ids.length > 0) {
        this.multiDeleteRecords(Ids).then(() => {
          this.fetchModels();
        });
      } else {
        // You can use a snackbar or any other UI component for this
        console.log('No Models selected for deletion.');
      }
    },
    handleCheckboxChange(item) {
      // Implement your logic here
      console.log('Checkbox changed for item:', item);
    },
  },
  created() {
      this.fetchSub_category();
  },
  watch: {
    $route: {
      async handler(route) {
        if (!route.query.page) {
          await this.$router
            .replace({
              query: { ...route.query, page: 1 },
            })
            .catch(() => {});
          return;
        }
        this.setFilter({ ...this.getFilter ,...route.query});
        this.$store.dispatch("Models/fetchModels").then(() => {
          this.loading = false;
        });
      },
      deep: true,
      immediate: true,
    },
    dialog(val) {
      if (!val) {
        this.sub_category_id = "";
        this.sub_categoryId = "";
        this.RegionId = "";
      }
    },
  },
};
</script>
