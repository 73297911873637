var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home page"},[_c('h2',{staticClass:"accent--text"},[_c('v-icon',{staticClass:"ml-3",attrs:{"color":"primary","large":""}},[_vm._v("mdi-monitor-dashboard")]),_vm._v(" ادارة الموديلات "),_c('back-button')],1),_c('hr',{staticClass:"my-5 header-divider"}),_c('div',{staticClass:"filter-box py-5 px-4 mb-6"},[_c('h3',{staticClass:"primary--text mb-3"},[_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-filter")]),_vm._v("تصنيف حسب ")],1),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-select',{attrs:{"rounded":"","append-icon":"mdi-map","outlined":"","items":_vm.getSub_category.map(function (ref) {
            var id = ref.id;
            var name = ref.name;

            return ({
              text: name,
              value: id,
            });
}),"label":"التصنيف"},on:{"input":_vm.changeSelected},model:{value:(_vm.getFilter.sub_category_id),callback:function ($$v) {_vm.$set(_vm.getFilter, "sub_category_id", $$v)},expression:"getFilter.sub_category_id"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","solo":"","hide-details":"","clearable":"","label":"ابحث بالاسم   "},model:{value:(_vm.filter.search),callback:function ($$v) {_vm.$set(_vm.filter, "search", $$v)},expression:"filter.search"}}),_c('v-btn',{staticClass:"my-3",attrs:{"large":"","color":"primary"},on:{"click":function($event){return _vm.handleFilters('search')}}},[_vm._v("بحث")])],1),_c('v-col',{attrs:{"md":"2"}}),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-btn',{staticClass:"my-2",attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){_vm.filter.active = true;
            _vm.handleFilters('active')}}},[_vm._v(" الموديلات المفعلة ")]),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){_vm.filter.active = false;
            _vm.handleFilters('active')}}},[_vm._v(" الموديلات الغير مفعلة ")]),_c('v-btn',{staticClass:"my-2",attrs:{"block":"","large":"","color":"primary"},on:{"click":function($event){return _vm.handleGetAll()}}},[_vm._v("عرض الكل ")])],1)],1)],1),_c('v-chip',{staticClass:"mb-2",attrs:{"right":"","label":"","color":"secondary"}},[_c('v-icon',{staticClass:"mx-2"},[_vm._v(" mdi-map ")]),_vm._v(" عدد الموديلات "+_vm._s(_vm.getTotal_Models))],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.getModelsList,"loading-text":"جاري تحميل البيانات","no-data-text":"لا يوجد بيانات","hide-default-footer":"","disable-filtering":"","disable-pagination":"","disable-sort":""},scopedSlots:_vm._u([{key:"item.checkbox",fn:function(ref){
            var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.handleCheckboxChange(item)}},model:{value:(item.selected),callback:function ($$v) {_vm.$set(item, "selected", $$v)},expression:"item.selected"}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"d-flex justify-end",attrs:{"flat":""}},[_c('v-dialog',{attrs:{"max-width":"500px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"my-2",attrs:{"color":"primary","dark":""}},'v-btn',attrs,false),on),[_vm._v(" إضافة موديل جديدة ")])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text"},[_c('span',{staticClass:"text-h5"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"rounded":"","append-icon":"mdi-map","outlined":"","items":_vm.getSub_category.map(function (ref) {
                        var id = ref.id;
                        var name = ref.name;

                        return ({
                          text: name,
                          value: id,
                        });
            }),"label":"التصنيف"},model:{value:(_vm.sub_category_id),callback:function ($$v) {_vm.sub_category_id=$$v},expression:"sub_category_id"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"الاسم بالعربية"},model:{value:(_vm.name_ar),callback:function ($$v) {_vm.name_ar=$$v},expression:"name_ar"}}),_c('v-text-field',{attrs:{"rounded":"","outlined":"","label":"الاسم بالانجليزية"},model:{value:(_vm.name_en),callback:function ($$v) {_vm.name_en=$$v},expression:"name_en"}}),_c('v-text-field',{attrs:{"outlined":"","rounded":"","type":"number","label":"الترتيب"},model:{value:(_vm.order),callback:function ($$v) {_vm.order=$$v},expression:"order"}}),_vm._v(" الحالة : "),_c('v-switch',{staticClass:"mx-7",attrs:{"inset":""},model:{value:(_vm.active),callback:function ($$v) {_vm.active=$$v},expression:"active"}})],1)],1)],1)],1),_c('hr',{staticClass:"header-divider primary"}),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"my-2 px-3 py-3",attrs:{"color":"blue darken-1","dark":""},on:{"click":function($event){return _vm.save()}}},[_vm._v(" حفظ ")]),_c('v-btn',{staticClass:"my-2 mx-2 px-3 py-3",attrs:{"color":"red darken-1","dark":""},on:{"click":function($event){return _vm.close()}}},[_vm._v(" الغاء ")])],1)],1)],1),_c('v-btn',{staticClass:"mr-1",attrs:{"color":"error"},on:{"click":_vm.deleteSelected}},[_vm._v("حذف المحدد")])],1)]},proxy:true},{key:"item.blocked",fn:function(ref){
                        var item = ref.item;
return [(item.active == 0)?_c('v-switch',{attrs:{"inset":"","input-value":false},on:{"change":function($event){return _vm.unblock(item)}}}):_c('v-switch',{attrs:{"color":"success","inset":"","input-value":true},on:{"change":function($event){return _vm.block(item)}}})]}},{key:"item.sub_category",fn:function(ref){
                        var item = ref.item;
return [_vm._v(" "+_vm._s(item.sub_category_name)+" ")]}},{key:"item.actions",fn:function(ref){
                        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click":function($event){return _vm.editItem(item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"item.delete",fn:function(ref){
                        var item = ref.item;
return [_c('v-dialog',{attrs:{"transition":"dialog-top-transition","max-width":"600"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                        var on = ref.on;
                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v(" حذف ")])]}},{key:"default",fn:function(dialog){return [_c('v-card',[_c('v-toolbar',{attrs:{"color":"red","dark":""}},[_vm._v("حذف موديل")]),_c('v-card-text',[_c('h4',{staticClass:"my-3 red--text"},[_vm._v("هل تريد حذف هذا الموديل")])]),_c('v-card-actions',{staticClass:"justify-end"},[_c('v-btn',{attrs:{"color":"red","dark":""},on:{"click":function($event){_vm.deleteThisRegion(item.id), (dialog.value = false)}}},[_vm._v(" حذف ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){dialog.value = false}}},[_vm._v("الغاء")])],1)],1)]}}],null,true)})]}}],null,true)}),_c('div',{staticClass:"text-center"},[_c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"8"}},[_c('v-container',{staticClass:"max-width"},[_c('v-pagination',{staticClass:"my-4",attrs:{"value":+_vm.getFilter.page,"circle":"","total-visible":"7","length":Number(_vm.getLastPage)},on:{"input":_vm.changePage}})],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }